:root {
	--color-white: #fff;
	--color-black: #222222;
	--color-black-light: #545151;
	--color-red: #ff5722;
	--color-warning: #ff9800;
	--color-primary: #b2000e;
	--color-primary-1: #b2000e;
	--color-primary-4: rgba(178, 0, 14, 0.15);
	--color-primary-5: rgba(178, 0, 14, 0.1);
	--color-text: #4c4c4c;
	--color-gray-500: #676767;
	--color-gray-text: #bababa;
	--color-gray-stroke: #d2d2d2;
	--color-gray-hover: #f2f2f2;
	--color-gray-bg: #f4f4f4;
	--shadow-regular: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.2);
	--shadow-light: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
	--tw-ring-offset-shadow: 0 0 transparent;
	--tw-ring-shadow: 0 0 transparent;
	--shadow-0: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		0 1px 2px 0 rgba(0, 0, 0, 0.05);
	--shadow-1: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	--shadow-2: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	--shadow-3: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	--shadow-4: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
	--shadow-5: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		0 25px 50px -12px rgba(0, 0, 0, 0.25);
	--shadow-in: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
	--shadow-out: var(--tw-ring-offset-shadow), var(--tw-ring-shadow);
	--shadow-white: 0 0 18px -6px rgb(0 0 0/16%), 0 2px 4px 0 rgb(0 0 0/6%);
}

.mr-10 {
	margin-right: 10px;
}

.mb-30 {
	margin-bottom: 30px;
}

.pos-content {
	width: 100%;
	max-width: 560px;
	min-height: 100vh;
	margin: auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex-grow: 1;
	position: relative;
	padding-bottom: 0;
	background: #fff;
	box-shadow: var(--shadow-light);
	padding: 0 0 80px;
}

.pos-content.second {
	padding: 0 0 150px;
}

.pos-header {
	display: flex;
	padding: 24px 0 40px;
}

.pos-image img {
	border: 1px solid;
	border-radius: 512px;
	margin-right: 20px;
	margin-left: 30px;
	width: 86px;
	height: 86px;
	object-fit: contain;
}

.pos-title {
	margin: 0 0 4px;
	font-weight: 500;
	color: var(--color-black);
	font-size: 26px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
}

.max-lines-3 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
}

.pos-info {
	margin-bottom: 8px;
	font-size: 14px;
	color: #989898;
	margin-top: 4px;
}

.pos-info__address {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
}

.pos-info__block {
	display: flex;
	align-items: center;
	margin-right: 8px;
	color: var(--color-black-light);
	font-weight: 500;
}

.pos-info__block i {
	font-size: 18px;
}

.pos-info__block:not(:last-child) {
	margin-right: 12px;
}

.pos-info__description {
	display: flex;
	flex-direction: column;
	grid-gap: 8px 0;
	gap: 8px 0;
}

.pos-content__footer {
	font-size: 12px;
	margin-top: auto;
	padding-bottom: 16px;
	padding-top: 16px;
	text-align: center;
	color: var(--color-gray-text);
}

.menu-list {
	padding: 15px 20px 80px;
	padding-bottom: 24px;
	background-color: #fff;
	border-radius: 30px 30px 0px 0px;
	margin-top: -30px;
}

.menu-item-search-form {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 64px;
	right: 5px;
	opacity: 0;
	transition: 0.3s;
}

.menu-item-search-form.show {
	opacity: 1;
	width: 100%;
	transition: 0.3s;
}

.menu-item-search-form.show .base-form-input {
	padding: 10px 16px;
	right: 5px;
	left: 5px;
	border: 1px solid #c5c5c5;
}

.menu-item-search-form.show .base-form-input::placeholder {
	transition: 0.3s;
	color: var(--placeholder-color);
}

.search-header.show .search-button {
	right: 10px;
}

.menu-item-search-form-field {
	position: relative;
	flex-grow: 1;
}

.search-header {
	position: relative;
	text-align: center;
	margin-bottom: 30px;
}

.back-button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 5px;
	font-size: 16px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.back-button i {
	font-size: 20px;
}

.search-button {
	position: absolute;
	transition: 0.3s;
	top: 50%;
	transform: translateY(-50%);
	right: 5px;
	background-color: var(--color-gray-bg);
	border-radius: 5px;
	width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.search-button i {
	font-size: 18px;
}

.base-form-input {
	width: 100%;
	background-color: var(--color-gray-bg);
	border-radius: 8px;
	line-height: 1.4;
	font-size: 14px;
	box-sizing: border-box;
	outline: none;
	border-radius: 10px;
	transition: all 0.3s;
	transition: border 0s;
	transition: padding 0s;
	padding: 0;
	border: none;
	height: 41px;
}

.base-form-input::placeholder {
	color: var(--color-gray-bg);
}

.round-button {
	position: relative;
	border: none;
	background: var(--color-white);
	border-radius: 100%;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 1px 1px 6px rgb(0 0 0 / 18%);
}

.menu-item {
	position: relative;
	width: 100%;
	overflow: hidden;
	margin-bottom: 30px;
}

.menu-item__link {
	color: #fff;
	border-radius: 26px;
}

.menu-item__link img {
	max-width: 100%;
	max-height: 130px;
	height: 130px;
	width: 100%;
	margin-bottom: 10px;
	border-radius: 10px;
	object-fit: contain;
}

.menu-item__link h2 {
	position: relative;
	font-size: 24px;
	color: #00233a;
}

.dish {
	position: relative;
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.base-lazy {
	position: relative;
}

.product-image .base-lazy__loader,
.product-image {
	border-radius: 26px;
}

.product-image {
	flex-shrink: 0;
	display: flex;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	position: relative;
	margin-bottom: 10px;
	width: 100%;
	overflow: hidden;
}

/* .base-lazy._loaded img {
	opacity: 1;
} */

.base-lazy img {
	position: relative;
	transition: opacity 0.25s;
}

.menu-item-list__item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: #000;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	position: relative;
	height: 100%;
}

.product-image {
	max-width: 100%;
	max-height: 100%;
	height: 130px;
	-o-object-fit: fill;
	object-fit: fill;
	-o-object-position: center;
	object-position: center;
	object-fit: contain;
}

.product-image .base-lazy__loader,
.product-image {
	border-radius: 26px;
}

.product-title {
	margin: 0 0 10px;
	font-size: 18px;
	font-weight: 600;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	flex-grow: 1;
	color: #00233A;
	font-weight: 400;
	font-size: 20px;
}

.product-description {
	position: relative;
	margin-bottom: 10px;
	color: inherit;
	font-size: 14px;
	overflow: hidden;
}

.product-footer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: auto;
}

.product-price {
	font-weight: 500;
	display: flex;
	align-items: baseline;
	line-height: 1;
	margin-bottom: 12px;
}

.product-price__current {
	display: flex;
	align-items: flex-start;
	font-size: 24px;
	color: #00233a;
}

.product-price__current span {
	font-size: 16px;
	margin-left: 2px;
	font-weight: 500;
}

.product-action {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.product-button {
	position: relative;
	border: none;
	z-index: 1;
	border-radius: 10px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	cursor: pointer;
	font-weight: 700;
	font-size: 18px;
	color: #00233A;
	width: calc(100% / 3);
}

.product-button i {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
}

.product-button.minus {
	background-color: #F24442;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
	border-radius: 10px;
	border: 1px solid transparent;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	-webkit-user-select: none;
	user-select: none;
	padding: 0;
	width: 45px;
	height: 37px;
}

.product-button.plus {
	background-color: #F8A91B;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
	border-radius: 10px;
	border: 1px solid transparent;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	-webkit-user-select: none;
	user-select: none;
	padding: 0;
	width: 45px;
	height: 37px;
}

.product-button.add,
.product-button.add:hover {
	width: 100%;
	background: #34b547;
	border-radius: 10px;
	border: 1px solid transparent;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	text-align: center;
	-webkit-user-select: none;
	user-select: none;
	vertical-align: middle;
	height: 37px;
}

.product-count {
	font-weight: 700;
	font-size: 18px;
	color: #00233A;
	width: calc(100% / 3);
	text-align: center;
	margin: auto;
}

.pos-order {
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	z-index: 5;
	position: fixed;
	left: 0;
	bottom: 0;
	color: var(--color-white);
	font-weight: 500;
	background: var(--color-primary);
	border: none;
}

.pos-order.second {
	display: flex;
	flex-direction: column;
}

.pos-order button {
	background: #34b547;
	width: 100%;
	border: none;
	color: #fff;
	padding: 0 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 20px;
	font-weight: bold;
	height: 62px;
}

.pos-order button i {
	font-size: 32px;
}

.pos-order-total {
	font-weight: 500;
	font-size: 16px;
	display: flex;
	align-items: flex-end;
	color: #FFF;
}

.pos-order-total-price {
	font-weight: 700;
	font-size: 24px;
	margin-left: 8px;
	line-height: 28px;
}

.pos-order:hover {
	color: var(--color-white);
}

.success-page {
	max-width: 560px;
	min-height: 100vh;
	background-color: #fff;
	color: #222222;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 36px;
	text-align: center;
	margin: auto;
}

.success-page span {
	font-weight: 500;
	font-size: 20px;
}

.success-page i {
	margin-top: 50px;
	font-size: 90px;
	color: #2e9e3e;
}

.menu-item-search {
	position: relative;
}

.search-result-list {
	position: absolute;
	background-color: #fff;
	top: 50px;
	width: 100%;
	z-index: 2;
	max-height: 500px;
	overflow: auto;
	box-shadow: var(--shadow-regular);
	padding: 15px 10px;
	transition: 0.3s;
}

.opacity-1 {
	opacity: 1;
}

.opacity-0 {
	opacity: 0;
}

.search-header-title {
	font-size: 24px;
	font-weight: 600;
	color: #000;
	transition: 0.3s;
}

.search-item {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	padding-bottom: 15px;
	border-bottom: 1px solid #F8F8F8;
}

.search-item:last-child {
	margin-bottom: 0;
}

.search-image img {
	width: 120px;
	height: 80px;
	margin-right: 20px;
	border-radius: 10px;
}

.search-title {
	font-weight: 400;
	font-size: 18px;
	margin-bottom: 5px;
}

.search-price {
	font-weight: 700;
	font-size: 20px;
	color: #00233A;
	display: flex;
	align-items: center;
	height: 100%;
}